import React from 'react';
import styled from 'styled-components';

const EmojiIcon = ({ children, padding = "0.125", size = "1.5" }) => (
  <Styled.EmojiIcon size={size} padding={padding}>
    {children}
  </Styled.EmojiIcon>
);

const Styled = {};

Styled.EmojiIcon = styled.div`
  font-size: ${({ size }) => `${size}rem`};
  padding: ${({ padding }) => `${padding}rem`};
`;

export default EmojiIcon;