import { css } from 'styled-components';

export const FontSizes = {
  size12: css`
    line-height: 0.75rem;
    font-size: 0.75rem;
  `,
  size13: css`
    line-height: 0.8125rem;
    font-size: 0.8125rem;
  `,
  size14: css`
    line-height: 0.875rem;
    font-size: 0.875rem;
  `,
  size15: css`
    line-height: 0.9375rem;
    font-size: 0.9375rem;
  `,
  size16: css`
    line-height: 1rem;
    font-size: 1rem;
  `,
  size24: css`
    line-height: 1.5rem;
    font-size: 1.5rem;
  `,
};
