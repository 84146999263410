import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';

export default function configureStore(preloadedState = {}) {
  const middlewares = [];
  const enhancers = [];

  if (process.env.REACT_APP_REDUX_LOGGING === 'true') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middlewares, thunk), ...enhancers);
  const store = createStore(createRootReducer(), preloadedState, composedEnhancers);
  return store;
}