export const BREAKPOINT = {
  MOBILE: 768,
  TABLET: 1024
};

export const BREAKPOINT_REM = {
  MOBILE: 48,
  TABLET: 64,
  DESKTOP_SMALL: 80
};

export const DESKTOP_HEADER_HEIGHT = 4.5;
export const MAX_PAGE_SIZE = 1280;
export const MOBILE_FOOTER_HEIGHT = 3.75;

export const MAIN_ROUTE_NAMES = {
  HOME: 'Home',
  COLLECTIONS: 'Collections',
};

export const ROUTES = {
  [MAIN_ROUTE_NAMES.HOME]: '/',
  [MAIN_ROUTE_NAMES.COLLECTIONS]: '/collections',
};
