import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';

import { setSelectedTheme } from 'actions/theme';
import { getSelectedTheme } from 'selectors/theme';

import { THEMES } from 'constants/theme';
import EmojiIcon from 'components/EmojiIcon';

const ThemeSwitcher = ({ className }) => {
  const dispatch = useDispatch();
  const selectedTheme = useSelector(getSelectedTheme);
  
  return (
    <Button
      onClick={() => dispatch(
        setSelectedTheme(selectedTheme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT)
      )}
      className={className}
      userSelect="none"
      width="5"
    >
      <EmojiIcon>
        {selectedTheme === THEMES.DARK ? '🌙' : '☀️'}
      </EmojiIcon>
    </Button>
  )
};

export default ThemeSwitcher;
