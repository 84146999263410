import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { breakpoints } from 'styles';

import { ROUTES, MAIN_ROUTE_NAMES } from 'constants/page';

import ThemeSwitcher from './ThemeSwitcher';

const Header = () => {

  return (
    <Styled.HeaderContainer>
      <nav>
        <Styled.Ul>
          {Object.values(MAIN_ROUTE_NAMES).map((route) => (
            <li key={route}>
              <Link to={ROUTES[route]}>{route}</Link>
            </li>
          ))}
        </Styled.Ul>
      </nav>
      <ThemeSwitcher />
    </Styled.HeaderContainer>
  );
};

const Styled = {};

Styled.HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

Styled.Ul = styled.ul`
  list-style: none;
  align-items: center;
  margin: auto;

  li {
    margin: 1rem;
    display: inline;
  }

  a {
    text-decoration: none;
    font-weight: 500;
  }

  a:visited {
    color: ${({ theme  }) => theme.text_dark};
  }
`;

export default Header;
