import React, { lazy, Suspense, useState  } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import { GlobalStyles } from './components/GlobalStyle';
import Header from 'components/Header';
import MobileFooter from 'components/MobileFooter';
import MobileNavigation from 'components/MobileNavigation';

import { getSelectedTheme } from './selectors/theme';

import { THEME_MAPPING } from './constants/theme';

const Home = lazy(() => import('./pages/Home'));
const Collections = lazy(() => import('./pages/Collections'));
const Octavas = lazy(() => import('./pages/Collections/Octavas'));

function App() {
  const selectedTheme = useSelector(getSelectedTheme);
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={THEME_MAPPING[selectedTheme]}>
        <GlobalStyles/>
        <Header />
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="collections">
              <Route index element={<Collections />} />
              <Route path="octavas" element={<Octavas />}>
                <Route path=":id" element={<Octavas />} />
              </Route>
            </Route>
          </Route>
        </Routes>
        <MobileNavigation setShowMobileNav={setShowMobileNav} showMobileNav={showMobileNav} />
        <MobileFooter setShowMobileNav={setShowMobileNav} showMobileNav={showMobileNav} />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
