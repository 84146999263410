import React from 'react'
import styled from 'styled-components'

export const BUTTON_SIZE = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
}

const Button = ({
  children,
  className,
  color,
  disabled,
  margin,
  onClick,
  size,
  userSelect,
  width,
}) => {
  return (
    <Styled.Button
      className={className}
      color={color}
      disabled={disabled}
      tabIndex="0"
      margin={margin}
      onClick={(e) => {
        e.preventDefault();
        !disabled && onClick?.();
      }}
      size={size}
      userSelect={userSelect}
      width={width}
    >
      {children}
    </Styled.Button>
  )
}

const Styled = {}

Styled.Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.border_radius};
  color: ${({ theme }) => theme.text};
  cursor: pointer;

  :hover {
    filter: ${({ theme }) => theme.button_hover};
  }

  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    
    :hover {
      filter: none;
    }
  `}
  
  margin: ${({ margin }) => margin};

  ${({ userSelect }) => userSelect && `user-select: ${userSelect}`};
  ${({ width }) => width && `width: ${width}rem`};

  ${({ color, theme }) => `
    background-color: ${color ?? theme.layer_lighter};
  `}

  ${({ size }) => {
    switch (size) {
      case BUTTON_SIZE.LARGE:
        return `
          height: 3.75rem;
        `
      case BUTTON_SIZE.SMALL:
        return `
          height: 1.25rem;
        `
      case BUTTON_SIZE.MEDIUM:
      default:
        return `
          height: 2.5rem;
        `
    }
  }}
`

export default Button
