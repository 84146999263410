import { setSelectedTheme } from 'actions/theme';

import { THEMES } from 'constants/theme';

import { getLocalStorage, LS_KEYS, setLocalStorage } from 'lib/localStorage';

const initialTheme = getLocalStorage({ key: LS_KEYS.theme });

const initialState = {
  selectedTheme: initialTheme ?? THEMES.LIGHT,
};

const themeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case setSelectedTheme().type: {
      setLocalStorage({ key: LS_KEYS.theme, value: payload });

      return {
        ...state,
        selectedTheme: payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default themeReducer;
