import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AbsolutePosition, breakpoints, FontSizes } from 'styles';

import ThemeSwitcher from 'components/Header/ThemeSwitcher';

import { MAIN_ROUTE_NAMES, MOBILE_FOOTER_HEIGHT, ROUTES } from 'constants/page';

const MobileNavigation = ({ setShowMobileNav, showMobileNav }) => (
    <Styled.MobileNavigationContainer showMobileNav={showMobileNav}>
      <nav>
        <ul>
          {Object.values(MAIN_ROUTE_NAMES).map((route) => (
            <Styled.Li key={route}>
              <Link
                to={ROUTES[route]}
                onClick={() => setShowMobileNav(!showMobileNav)}
              >
                {route}
              </Link>
            </Styled.Li>
          ))}
        </ul>
      </nav>
      <Styled.ThemeSwitcher />
    </Styled.MobileNavigationContainer>
  );

const Styled = {};

Styled.ThemeSwitcher = styled(ThemeSwitcher)`
  margin: 1rem;
`;

Styled.MobileNavigationContainer = styled.div`
  ${AbsolutePosition}
  bottom: ${MOBILE_FOOTER_HEIGHT}rem;
  display: grid;
  backdrop-filter: blur(1rem);
  align-items: flex-end;
  grid-template-columns: 1fr auto;

  ${({ showMobileNav }) => !showMobileNav && `display: none;`}

  @media ${breakpoints.notTablet} {
    display: none;
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }
`;

Styled.Li = styled.li`
  ${FontSizes.size24}
  padding: 1rem;
`;
export default MobileNavigation;
