import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AbsolutePosition, breakpoints } from 'styles';

import EmojiIcon from 'components/EmojiIcon';
import Button from 'components/Button';

import { MOBILE_FOOTER_HEIGHT } from 'constants/page';

const MobileFooter = ({ setShowMobileNav, showMobileNav }) => {

  return (
    <Styled.FooterContainer>
      <Button userSelect="none" width="5" onClick={() => setShowMobileNav(!showMobileNav)}>
        <EmojiIcon>🍔</EmojiIcon>
      </Button>
    </Styled.FooterContainer>
  );
};

const Styled = {};

Styled.FooterContainer = styled.div`
  @media ${breakpoints.notTablet} {
    display: none;
  }

  ${AbsolutePosition}
  top: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: 0 1rem;
  height: ${MOBILE_FOOTER_HEIGHT}rem;

  nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export default MobileFooter;
