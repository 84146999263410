export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light'
};

const SHARED_THEMES = {
  border_radius: '4px',
}

export const THEME_MAPPING = {
  [THEMES.DARK]: {
    // Text color
    linkColor: '#bebebe',
    text: '#FAFAFA',
    text_dark: '#817F9B',

    // Background
    background: '#2E2C48',

    // Overlaying the background in order
    layer_light: '#3D3B57',
    layer_lighter: '#514D6E',

    // Effect
    button_hover: 'brightness(1.5)',
    text_hover: 'brightness(1.5)',
    
    ...SHARED_THEMES,
  },
  [THEMES.LIGHT]: {   
    linkColor: 'tomato',
    text: '#5D5971',
    text_dark: '#A5A5A5',

    background: '#FFFFFF',

    layer_light: '#F6F6F6',
    layer_lighter: '#EAEAEA',

    button_hover: 'drop-shadow(0 0 0.1rem #2E2C48)',
    text_hover: 'brightness(0.5)',

    ...SHARED_THEMES,
  }
};
